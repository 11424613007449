import React, { useState } from "react";
import { useKeepBookingContent } from "./useKeepBookingContent";
import { isPresent } from "src/utils/checks";
import { KeepBookingProps } from "./KeepBooking";
import { useKeepBooking } from "./useKeepBooking";
import type { BookingDeleted } from "@silversea-ssc/dotcom-api-types";
import { useSendUserEvent } from "src/utils/useSendUserEvent";
import { checkAndUpdateCounter, resetCounter } from "./keep-booking-counter";
import { ErrorBoundary } from "@rollbar/react";

const KeepBooking = React.lazy(() => import("./KeepBooking"));

type Props = Pick<KeepBookingProps, "pushDown">;

const MaybeKeepBooking: React.FC<Props> = (props) => {
  const [isVisible, setIsVisible] = useState(true);
  const [deleteBooking, setDeleteBooking] = useState<
    BookingDeleted | undefined
  >(undefined);

  const response = useKeepBooking();
  const maybeCruiseContent = useKeepBookingContent(
    response?.cruiseCode,
    response?.suiteCategory
  );
  const deleteBookingResponse = useSendUserEvent(deleteBooking);

  const cruiseContent = maybeCruiseContent.data?.result;

  if (
    !response ||
    maybeCruiseContent.status != "success" ||
    !isPresent(cruiseContent) ||
    deleteBookingResponse.status === "success" ||
    !isVisible
  ) {
    // TODO: What should we do if the response is not valid?
    return null;
  }

  const onClose = () => {
    if (!checkAndUpdateCounter(response.cruiseCode)) {
      setIsVisible(false);
      return;
    }

    setDeleteBooking(buildBookingDeleted());
    resetCounter(response.cruiseCode);
    setIsVisible(false);
  };

  return (
    <ErrorBoundary>
      <React.Suspense>
        <KeepBooking
          data={response}
          content={cruiseContent}
          isClosing={deleteBookingResponse.isLoading && !!deleteBooking}
          onClose={onClose}
          {...props}
        />
      </React.Suspense>
    </ErrorBoundary>
  );
};

const buildBookingDeleted = (): BookingDeleted => ({
  type: "BookingDeleted"
});

export default MaybeKeepBooking;
