import { useFetchApi } from "src/ssc-api/useFetchApi";
import { useUserId } from "src/utils/UserIdProvider";
import { PostUserEventsRequest } from "@silversea-ssc/dotcom-api-types";

export const useSendUserEvent = (event: PostUserEventsRequest | undefined) => {
  const userId = useUserId();
  const body = event ? JSON.stringify(event) : undefined;

  return useFetchApi(
    userId && body
      ? new Request(`${process.env.GATSBY_DIGITAL_API_BASE_URL}/userEvents`, {
          method: "post",
          body,
          credentials: "include"
        })
      : undefined,
    [body]
  );
};
