import { KeepBookingResponse } from "@silversea-ssc/dotcom-api-types";
import { isEnvFeatureEnabled } from "src/utils/feature-flags";
import { useUserId } from "src/utils/UserIdProvider";
import { buildGetRequest, useFetchApi } from "src/ssc-api/useFetchApi";

const keepBookingForDev: KeepBookingResponse = {
  adults: 2,
  kids: 0,
  state: "GuestInfoVisited",
  cruiseCode: "RA250201020",
  fare: "DoorToDoor",
  returnUrl: "https://quote.silversea.com/v3/RA250201020",
  hasFlights: true,
  suiteCategory: "DX",
  suiteNumber: "123"
};

export const useKeepBooking = () => {
  const userId = useUserId();
  const withMockedData = isEnvFeatureEnabled(
    process.env.GATSBY_MOCKED_KEEP_BOOKING
  );

  const response = useFetchApi(
    userId && !withMockedData
      ? new Request(buildGetRequest("keepBooking"), {
          method: "get",
          credentials: "include"
        })
      : undefined,
    undefined,
    false
  );

  if (withMockedData) return keepBookingForDev;

  return response.status === "success"
    ? (response.data as KeepBookingResponse)
    : undefined;
};
