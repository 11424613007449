export default {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID || "",
  dataset: process.env.GATSBY_SANITY_DATASET || "",
  apiVersion: "2021-08-31"
};

function removeDraft(pageId: undefined | null): undefined;
function removeDraft(pageId: string): string;
function removeDraft(pageId: string | undefined | null): string | undefined {
  if (!pageId) return undefined;
  return pageId.replace("drafts.", "");
}

export { removeDraft };
